<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    color="dark"
  >
    <div>
      <el-form label-width="30%">
        <el-form-item :label="$t('cloud.title.app_use_setting')" class="mb-1">
          <el-select v-model="apps" multiple class="w-100">
            <el-option v-for="app in capability.cloud_apps" :label="$t(`cloud.title.app_${app}`)" :value="app" :key="`app-opts-${app}`" />
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <template #header>
      <h6 class="modal-title">{{ $t('cloud.title.applications') }}</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
      <CButton @click="onOkay" color="primary">{{$t('button.apply')}}</CButton>
    </template>
  </CModal>
</template>

<script>
import {mapState} from 'vuex'
import axios from 'axios'

export default {
  name: 'AppsModal',

  computed: {
    ...mapState([
      'capability'
    ])
  },
  data() {
    return {
      loading: false,
      show: false,
      apps: []
    }
  },
  methods: {
    open() {
      this.show = true;
      this.apps = this.$utility.copy(this.capability.organization.apps);
    },
    onCancel() {
      this.show = false;
    },
    onOkay() {
      axios.post('/api/organizations/app_use/', this.apps)
        .then(() => {
          this.$notify.success({
            title: this.$t('msgbox.request_ok_title'),
            message: this.$t('msgbox.request_ok_content'),
            offset: 30
          });
          window.location.reload();
        })
        .catch(() => {
          this.$notify.error({
            title: this.$t('msgbox.request_fail_title'),
            message: this.$t('msgbox.request_fail_content'),
            offset: 30
          });
        })
    }
  }
}
</script>
