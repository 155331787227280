<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    size="xl"
    color="dark"
  >
    <input type="file" ref="fileInput" accept="image/jpeg,image/png" @change="handleImage" hidden/>
    <CAlert :show.sync="dismissCountDown" color="danger" fade>{{ notification }}</CAlert>

    <el-form label-width="25%" @submit.native.prevent>
      <el-form-item v-if="show_crop_ui" label="Logo Image">
        <div class="mb-1">
          <Jcrop
            :src="image_uri"
            @update="onCrop"
            :rect="rect"
            :options="options"
          />
        </div>
        <canvas ref="cropCanvas" class="border"></canvas>
      </el-form-item>
      <el-form-item v-else label="Logo Image">
        <div class="mb-1">
          <el-button type="primary" size="small" @click="onUpload">Select</el-button>
        </div>
        <img v-if="record.logo" :src="record.logo">
      </el-form-item>
    </el-form>

    <template #header>
      <h6 class="modal-title">Logo</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
      <CButton @click="onSubmit" color="primary">{{$t('button.submit')}}</CButton>
    </template>
  </CModal>
</template>

<script>
import axios from 'axios';
import { Jcrop } from 'vue-jcrop';
import utility from '@/utility.js';
import { mapState } from 'vuex';

export default {
  name: 'OrganizationForm',
  components: {
    Jcrop
  },
  data() {
    return {
      show: false,

      record: {
        id: null,
        logo: null
      },

      show_crop_ui: false,
      rect: [0, 0, 256, 56],
      image_uri: null,
      position: {},
      options: {
        handles: ['se'],
        aspectRatio: 256 / 56
      },
      image_crop_uri: null,
      image_blob: null,

      notification: '',
      dismissCountDown: 0
    }
  },
  computed : {
    ...mapState([
      'capability'
    ]),
  },
  methods: {
    open() {
      this.onCancel();
      this.record = {
        id: this.capability.organization.id,
        logo: this.capability.organization.logo
      }
      this.show = true;
    },
    onCancel() {
      this.show = false;

      this.name = null;
      this.logo = null;
      this.max_user = null;
      this.max_device = null;

      this.show_crop_ui = false;
      this.rect = [0, 0, 256, 56];
      this.orig_image = null;
      this.image_uri = null;
      this.position = {};
      this.options = {
        handles: ['se'],
        aspectRatio: 256 / 56
      };
      this.image_crop_uri = null;
      this.image_blob = null;
      this.$refs.fileInput.value = null;
      this.step = 0;
    },
    validateAndWarning() {
      return null;
    },
    onSubmit() {
      if (this.validateAndWarning()) {
        return;
      }

      let blob = null;
      if (this.image_crop_uri) {
        blob = utility.DataURI2Blob(this.image_crop_uri);
      }
      const fd = new FormData;
      if (blob) {
        fd.append("image", blob, "logo_145x35.jpg");
      }

      // this.$emit('payload', fd, this.organization_updated.id);
      axios.put(`/api/organizations/${this.capability.organization.id}/logo/`, fd)
        .then(() => {
          window.location.reload();
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: this.$t('cloud.title.error'),
            message: `${this.$t('cloud.title.request_fail')}: ${error.response.data.error}`,
            offset: 30
          });
        })
      this.onCancel();
    },

    cropImage(imageObj, x, y, w, h) {
      utility.EraseCanvas(this.$refs.cropCanvas);
      const canvas = this.$refs.cropCanvas;
      canvas.width = w;
      canvas.height = h;
      const ctx = canvas.getContext('2d');
      ctx.save();
      ctx.drawImage(imageObj, x,y,w,h, 0,0,w,h);
      ctx.restore();
      this.image_crop_uri = canvas.toDataURL('image/jpeg', 1.0);
    },
    handleImage(e) {
      if (e.target.files.length === 0) {
        return;
      }
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const reader = new FileReader;
      const img = new Image;

      reader.onload = event => {
        img.onload = () => {
          // console.log(img.width, img.height);
          if (img.width < 256 || img.height < 56) {
            this.notification = 'Image is too small. Minimum size is 256x56.';
            this.dismissCountDown = 3;
            return;
          }
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          this.image_uri = utility.ContainImageSize(img, 540, 400);
          this.show_crop_ui = true;
        }
        img.src = event.target.result;
      }
      reader.readAsDataURL(e.target.files[0]);
    },
    onUpload() {
      this.$refs.fileInput.click();
    },
    onCrop(e) {
      this.position = e.sel.round();
      const img = new Image;
      img.onload = () => {
        const position = this.position;
        this.cropImage(img, position.x, position.y, position.w, position.h);
      }
      img.src = this.image_uri;
    }
  }
}
</script>

<style lang="scss" scoped>
.normal-input {
  width: 80%;
}
.short-input {
  width: 40%;
}
</style>